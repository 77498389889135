<template>
  <div class="learning">
    <TopBar :type="2" :pageTitle="'学习记录'" />
    <ul class="class-list">
      <li class="class-list-info" v-for="item in studyRecord" :key="item.id">
        <!-- <router-link :to="'/recordedInfo/'+item.id"> -->
          <img class="info-img" :src="item.image" alt="">
          <div class="info-content">
            <div class="info-title vertical-ellipsis">{{item.name}}</div>
            <div class="progress">
              <div class="num">{{item.progress}}%</div>
              <div class="progress-bg">
                <div class="progress-line" :style="{ width: item.progress + '%' }"></div>
              </div>
            </div>
          </div>
        <!-- </router-link> -->
      </li>
      <li class="nodata" v-if="studyRecord && studyRecord.length == 0">
        <img class="nodata-img" :src="require('@/assets/no-data.png')">
        <p class="nodata-text">班级内还没有课程</p>
        <!-- <router-link :to="'/index'" class="back-index">返回首页</router-link> -->
      </li>
    </ul>
  </div>
</template>
<script>
import TopBar from '@/m/common/topBar.vue'
export default {
  name: 'learning',
  data(){
    return {
      studyRecord: null,  //记录
    }
  },
  components: {
    TopBar
  },
  mounted(){
    this.getStudyRecord(); //获取记录
  },
  methods: {
    //获取记录
    getStudyRecord(){
      this.$axios.post(`/v1/personal/studyRecord`, {}, {useLog: true}).then(res => {
        this.studyRecord = res.data;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.class-list{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 28px 28px 0;
}
.class-list-info{
  width: 335px;
  box-shadow: 0px 0px 12px 0px rgba(0, 12, 40, 0.14);
  border-radius: 8px;
  margin-bottom: 24px;
  .info-img{
    width: 100%;
    height: 186px;
  }
  .info-content{
    padding: 9px 16px 24px 20px;
  }
  .info-title{
    height: 70px;
    font-size: 28px;
    color: #333;
    line-height: 35px;
  }
  .progress{
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .num{
      font-size: 24px;
      color: #000;
      text-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);
    }
    .progress-bg,
    .progress-line{
      width: 230px;
      height: 7px;
      background: #E5E5E5;
      box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.3);
      border-radius: 3px;
    }
    .progress-line{
      background: #254ED4;
    }
  }
}
.nodata{
  width: 574px;
  padding-top: 200px;
  margin: 0 auto;
  text-align: center;
  .nodata-img{
    width: 100%;
    height: auto;
  }
  .nodata-text{
    font-size: 26px;
    color: #999;
    padding-top: 140px;
  }
  .back-index{
    display: inline-block;
    padding: 10px;
    margin: 48px auto 0;
    font-size: 28px;
    color: #999;
  }
}
</style>
